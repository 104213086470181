export const useHydrateHook = () => {
  const hydrateReady = ref(false)
  // check nuxt ready
  onMounted(() => {
    hydrateReady.value = true
  })

  return {
    hydrateReady
  }
}
